import React from "react";

export default function MonthlyPage2({
  campaign,
  report,
  lastCampaign,
  lastReport,
  companyName,
}) {
  function main() {
    if (campaign && report) {
      return (
        <>
          <div className="row">
            <div className="col-md-6 left-side">
              <h1
                className="heading"
                style={{
                  fontSize: "3vh",
                }}
              >
                {campaign.settings.subjectLine}
              </h1>
              <h2
                style={{
                  marginTop: "10px",
                  fontSize: "3vh",
                  fontStyle: "italic",
                  fontWeight: 400,
                  width: "fit-content",
                }}
              >
                {campaign.settings.previewText}
              </h2>
            </div>
            <div className="col-md-6 right-side" style={{ padding: 0 }}>
              <table className="data-table">
                <tbody>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(campaign.recipients.recipientCount / 1000) >
                        0
                          ? Math.floor(
                              campaign.recipients.recipientCount / 1000
                            ) +
                            "," +
                            (campaign.recipients.recipientCount % 1000 < 100
                              ? (campaign.recipients.recipientCount % 1000) %
                                  100 <
                                10
                                ? `00${
                                    campaign.recipients.recipientCount % 1000
                                  }`
                                : `0${
                                    campaign.recipients.recipientCount % 1000
                                  }`
                              : campaign.recipients.recipientCount % 1000)
                          : campaign.recipients.recipientCount}
                      </h3>
                      <h3 className="secondary-number">
                        {lastCampaign
                          ? Math.floor(
                              lastCampaign.recipients.recipientCount / 1000
                            ) > 0
                            ? Math.floor(
                                lastCampaign.recipients.recipientCount / 1000
                              ) +
                              "," +
                              (lastCampaign.recipients.recipientCount % 1000 <
                              100
                                ? (lastCampaign.recipients.recipientCount %
                                    1000) %
                                    100 <
                                  10
                                  ? `00${
                                      lastCampaign.recipients.recipientCount %
                                      1000
                                    }`
                                  : `0${
                                      lastCampaign.recipients.recipientCount %
                                      1000
                                    }`
                                : lastCampaign.recipients.recipientCount % 1000)
                            : lastCampaign.recipients.recipientCount
                          : 0}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Recipients</h3>
                      <h5>Number of subscribed contacts in this audience</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(
                          (campaign.recipients.recipientCount -
                            (report.bounces.hardBounces +
                              report.bounces.softBounces)) /
                            1000
                        ) > 0
                          ? Math.floor(
                              (campaign.recipients.recipientCount -
                                (report.bounces.hardBounces +
                                  report.bounces.softBounces)) /
                                1000
                            ) +
                            "," +
                            ((campaign.recipients.recipientCount -
                              (report.bounces.hardBounces +
                                report.bounces.softBounces)) %
                              1000 <
                            100
                              ? ((campaign.recipients.recipientCount -
                                  (report.bounces.hardBounces +
                                    report.bounces.softBounces)) %
                                  1000) %
                                  100 <
                                10
                                ? `00${
                                    (campaign.recipients.recipientCount -
                                      (report.bounces.hardBounces +
                                        report.bounces.softBounces)) %
                                    1000
                                  }`
                                : `0${
                                    (campaign.recipients.recipientCount -
                                      (report.bounces.hardBounces +
                                        report.bounces.softBounces)) %
                                    1000
                                  }`
                              : (campaign.recipients.recipientCount -
                                  (report.bounces.hardBounces +
                                    report.bounces.softBounces)) %
                                1000)
                          : campaign.recipients.recipientCount -
                            (report.bounces.hardBounces +
                              report.bounces.softBounces)}
                      </h3>
                      <h3 className="secondary-number">
                        {lastCampaign || lastReport
                          ? Math.floor(
                              (lastCampaign.recipients.recipientCount -
                                (lastReport.bounces.hardBounces +
                                  lastReport.bounces.softBounces)) /
                                1000
                            ) > 0
                            ? Math.floor(
                                (lastCampaign.recipients.recipientCount -
                                  (lastReport.bounces.hardBounces +
                                    lastReport.bounces.softBounces)) /
                                  1000
                              ) +
                              "," +
                              ((lastCampaign.recipients.recipientCount -
                                (lastReport.bounces.hardBounces +
                                  lastReport.bounces.softBounces)) %
                                1000 <
                              100
                                ? ((lastCampaign.recipients.recipientCount -
                                    (lastReport.bounces.hardBounces +
                                      lastReport.bounces.softBounces)) %
                                    1000) %
                                    100 <
                                  10
                                  ? `00${
                                      (lastCampaign.recipients.recipientCount -
                                        (lastReport.bounces.hardBounces +
                                          lastReport.bounces.softBounces)) %
                                      1000
                                    }`
                                  : `0${
                                      (lastCampaign.recipients.recipientCount -
                                        (lastReport.bounces.hardBounces +
                                          lastReport.bounces.softBounces)) %
                                      1000
                                    }`
                                : (lastCampaign.recipients.recipientCount -
                                    (lastReport.bounces.hardBounces +
                                      lastReport.bounces.softBounces)) %
                                  1000)
                            : lastCampaign.recipients.recipientCount -
                              (lastReport.bounces.hardBounces +
                                lastReport.bounces.softBounces)
                          : 0}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Sent</h3>
                      <h5>Total number of newsletters delivered</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {report.bounces.hardBounces +
                          report.bounces.softBounces}
                      </h3>
                      <h3 className="secondary-number">
                        {lastReport
                          ? lastReport.bounces.hardBounces +
                            lastReport.bounces.softBounces
                          : 0}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Bounced</h3>
                      <h5>
                        {report.bounces.hardBounces} hard bounces and{" "}
                        {report.bounces.softBounces} soft bounces
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">{report.unsubscribed}</h3>
                      <h3 className="secondary-number">
                        {lastReport ? lastReport.unsubscribed : 0}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Unsubscribed</h3>
                      <h5>Number of contacts who have opted out</h5>
                    </td>
                  </tr>
                  <tr className="blank-row">
                    <td colSpan="3"></td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(campaign.reportSummary.uniqueOpens / 1000) >
                        0
                          ? Math.floor(
                              campaign.reportSummary.uniqueOpens / 1000
                            ) +
                            "," +
                            (campaign.reportSummary.uniqueOpens % 1000 < 100
                              ? (campaign.reportSummary.uniqueOpens % 1000) %
                                  100 <
                                10
                                ? `00${
                                    campaign.reportSummary.uniqueOpens % 1000
                                  }`
                                : `0${
                                    campaign.reportSummary.uniqueOpens % 1000
                                  }`
                              : campaign.reportSummary.uniqueOpens % 1000)
                          : campaign.reportSummary.uniqueOpens}
                      </h3>
                      <h3 className="secondary-number">
                        {lastCampaign
                          ? Math.floor(
                              lastCampaign.reportSummary.uniqueOpens / 1000
                            ) > 0
                            ? Math.floor(
                                lastCampaign.reportSummary.uniqueOpens / 1000
                              ) +
                              "," +
                              (lastCampaign.reportSummary.uniqueOpens % 1000 <
                              100
                                ? (lastCampaign.reportSummary.uniqueOpens %
                                    1000) %
                                    100 <
                                  10
                                  ? `00${
                                      lastCampaign.reportSummary.uniqueOpens %
                                      1000
                                    }`
                                  : `0${
                                      lastCampaign.reportSummary.uniqueOpens %
                                      1000
                                    }`
                                : lastCampaign.reportSummary.uniqueOpens % 1000)
                            : lastCampaign.reportSummary.uniqueOpens
                          : 0}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Opened</h3>
                      <h5>Number of people who opened newsletters</h5>
                    </td>
                  </tr>
                  <tr>
                    <td className="percentage-number">
                      <h3 className="primary-number">
                        {(
                          Math.round(
                            campaign.reportSummary.openRate * 1000
                          ).toFixed(2) / 10
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}
                        %
                      </h3>
                      <h3 className="secondary-number">
                        {lastCampaign
                          ? (
                              Math.round(
                                lastCampaign.reportSummary.openRate * 1000
                              ).toFixed(2) / 10
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            })
                          : 0}
                        %
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Open Rate</h3>
                      <h5>Percent of sent newsletters that were opened</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(campaign.reportSummary.opens / 1000) > 0
                          ? Math.floor(campaign.reportSummary.opens / 1000) +
                            "," +
                            (campaign.reportSummary.opens % 1000 < 100
                              ? (campaign.reportSummary.opens % 1000) % 100 < 10
                                ? `00${campaign.reportSummary.opens % 1000}`
                                : `0${campaign.reportSummary.opens % 1000}`
                              : campaign.reportSummary.opens % 1000)
                          : campaign.reportSummary.opens}
                      </h3>
                      <h3 className="secondary-number">
                        {lastCampaign
                          ? Math.floor(
                              lastCampaign.reportSummary.opens / 1000
                            ) > 0
                            ? Math.floor(
                                lastCampaign.reportSummary.opens / 1000
                              ) +
                              "," +
                              (lastCampaign.reportSummary.opens % 1000 < 100
                                ? (lastCampaign.reportSummary.opens % 1000) %
                                    100 <
                                  10
                                  ? `00${
                                      lastCampaign.reportSummary.opens % 1000
                                    }`
                                  : `0${
                                      lastCampaign.reportSummary.opens % 1000
                                    }`
                                : lastCampaign.reportSummary.opens % 1000)
                            : lastCampaign.reportSummary.opens
                          : 0}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Views</h3>
                      <h5>
                        Total number of times the newsletter has been opened
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="legend">
                <div className="legend-item">
                  <span className="legend-symbol legend-symbol-1">●</span>
                  This Month
                </div>
                <div className="legend-item">
                  <span className="legend-symbol legend-symbol-2">●</span>
                  Previous Month
                </div>
              </div>
            </div>
          </div>
          <img className="hitsend-logo " src={"/images/HitSend-logo.png"} alt="HitSend Logo" />
        </>
      );
    }
  }
  return <>{main()}</>;
}
