import React, { useState, useEffect } from "react";
import ReportAPIService from "../api/reports.api";

export default function MonthlyPage1Cloudbase({
  campaign,
  monthlyDate,
  companyName,
  previewHtml,
  previewRefPage1,
  phoneImageRef,
}) {
  const [logo, setLogo] = useState("");
  const date = new Date(
    campaign.sendTime !== null && campaign.sendTime !== undefined
      ? campaign.sendTime
      : new Date()
  );

  useEffect(() => {
    if (previewRefPage1.current) {
      previewRefPage1.current.innerHTML = previewHtml;
    }
  }, [previewRefPage1, previewHtml]);

  useEffect(() => {
    async function getLogo() {
      const [error, imageName] = await ReportAPIService.GetCompanyImage(companyName);
      if (error) {
        console.error("Error fetching company image:", error);
      }
      setLogo(`/images/${imageName}`);
    }
    
    if (logo === "") {
      getLogo();
    }
    
}, [companyName, logo]);

  function changeDate(date) {
    const day = date.getDate();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][date.getMonth()];
    const year = date.getFullYear();

    let dayString = day.toString();
    let suffix = "";

    if (
      dayString.length === 1 ||
      (dayString.length > 1 && dayString.charAt(dayString.length - 2) !== "1")
    ) {
      switch (dayString.charAt(dayString.length - 1)) {
        case "1":
          suffix = "st";
          break;
        case "2":
          suffix = "nd";
          break;
        case "3":
          suffix = "rd";
          break;
        default:
          suffix = "th";
          break;
      }
    } else {
      suffix = "th";
    }

    const formattedDate = `${day}${suffix} ${month} ${year}`;
    return formattedDate;
  }

  function main() {
    return (
      <>
        <div className="row content">
          <div className="col-md-6 left-side">
            <div className="row title">
              <div>
                <h1
                  className="heading"
                  style={{
                    fontSize: "5.4vh",
                  }}
                >
                  NEWSLETTER
                </h1>
              </div>
              <div>
                <h1
                  className="heading"
                  style={{
                    fontSize: "5.4vh",
                  }}
                >
                  REPORT
                </h1>
              </div>
              <br />
              <img
                src={logo}
                className={companyName === "system7" ? "system7-logo" : ""}
                alt="Client Logo"
                style={{
                  width: "auto",
                  height: "auto",
                  maxWidth: "50vh",
                  minHeight: "7vh",
                  maxHeight: "15vh",
                }}
              />
            </div>
            <div className="sub-title">
              <div className="heading-subheading">
                <h4 className="heading">Campaign: </h4>
                <h4 className="sub-heading">
                  {campaign.settings.title !== null &&
                  campaign.settings.title !== undefined
                    ? campaign.settings.title
                    : ""}
                </h4>
              </div>
              <div className="heading-subheading">
                <h4 className="heading">Audience: </h4>
                <h4 className="sub-heading">
                  {campaign.recipients.listName !== null &&
                  campaign.recipients.listName !== undefined
                    ? campaign.recipients.listName
                    : ""}
                </h4>
              </div>
              <div
                className="heading-subheading"
                style={{ marginBottom: "9.7vh" }}
              >
                <h4 className="heading">Delivery Date: </h4>
                <h4 className="sub-heading">{changeDate(date)}</h4>
              </div>
              <h4 className="heading">
                Report prepared for{" "}
                {campaign.settings.fromName !== null &&
                campaign.settings.fromName !== undefined
                  ? campaign.settings.fromName
                  : ""}
                , {changeDate(monthlyDate)}
              </h4>
            </div>
          </div>
          <div className="col-md-6 right-side">
            <div className="previewHtml">
              <img
                ref={phoneImageRef}
                src={"/images/phone.png"}
                alt="Phone"
                style={{
                  width: "19vw",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  right: "0",
                }}
              />
              <div className="campaign-preview" id="campaign-preview">
                <div ref={previewRefPage1} id="campaign-preview-id" />
              </div>
            </div>
            <div className="circle"></div>
          </div>
        </div>
        <img className="hitsend-logo " src={"/images/HitSend-logo.png"} alt="HitSend Logo" />
      </>
    );
  }

  return <>{main()}</>;
}
