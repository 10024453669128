import ReportAPIService from "../api/reports.api.js";
import FormatNumbersFunctions from "./FormatNumbersFunctions.js";
import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

// URL FOR API CALL
var startingUrl = "hitsendreporting.s05.system7.co.nz/api"
// var startingUrl = "localhost:44315"

class HelperFunctions {
  getStartingUrl() {
    return startingUrl;
  }

  async generateImages(companyName) {
    // Select the elements you want to capture
    const components = document.querySelectorAll(".component-to-capture");

    for (let index = 0; index < components.length; index++) {
      const component = components[index];

      // Capture the component as an image using html2canvas
      const canvas = await html2canvas(component);

      // Convert the canvas to a data URL (image)
      const imgData = canvas.toDataURL("image/png");

      // Create a link element to save the component image as a file
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${companyName}_${index + 1}.png`;
      link.style.display = "none"; // Hide the link

      // Trigger a click event on the link to save the component image
      link.click();
    }
  }

  async generateIframe(companyName, previewHtml) {
    // Create the elements
    const previewHtmlDiv = document.createElement("div");
    previewHtmlDiv.className = "previewHtml";
    previewHtmlDiv.style.width = "490px";
    previewHtmlDiv.style.height = "755px";

    const phoneImage = document.createElement("img");
    phoneImage.setAttribute("ref", "phoneImageRef");
    phoneImage.setAttribute("src", "/images/phone.png");
    phoneImage.setAttribute("alt", "Phone");
    phoneImage.style.height = "100%";
    phoneImage.setAttribute("width", "380");
    phoneImage.style.position = "absolute";
    phoneImage.style.top = "0";
    phoneImage.style.right = "0";

    const campaignPreviewDiv = document.createElement("div");
    campaignPreviewDiv.className = "campaign-preview";
    campaignPreviewDiv.id = "campaign-preview";
    campaignPreviewDiv.style.width = "330px";
    campaignPreviewDiv.style.height = "720px";
    campaignPreviewDiv.style.marginLeft = "135px";
    campaignPreviewDiv.style.marginTop = "15px";

    const previewIframe = document.createElement("iframe");
    previewIframe.setAttribute("ref", "previewRefPage1");
    previewIframe.setAttribute("title", "campaign-preview");
    previewIframe.setAttribute("srcdoc", previewHtml);
    previewIframe.setAttribute("scrolling", "no");
    previewIframe.setAttribute("width", "330");
    previewIframe.setAttribute("height", "720");

    // Append elements to respective parents
    campaignPreviewDiv.appendChild(previewIframe);
    previewHtmlDiv.appendChild(phoneImage);
    previewHtmlDiv.appendChild(campaignPreviewDiv);

    // Append the main div to the document body
    document.body.appendChild(previewHtmlDiv);

    // Use html2canvas to capture the rendered HTML to an image
    const canvas = await html2canvas(previewHtmlDiv, {
      backgroundColor: "transparent",
    });

    // Convert the canvas to a data URL (image)
    const imgData = canvas.toDataURL("image/png");

    // Create a link element to save the component image as a file
    const link = document.createElement("a");
    link.href = imgData;
    link.download = `${companyName}_iframe.png`;
    link.style.display = "none"; // Hide the link

    // Trigger a click event on the link to save the component image
    link.click();

    document.body.removeChild(previewHtmlDiv);
  }

  // async generatePDF() {
  //   const pdf = new jsPDF("l", "mm", "a4");

  //   // Select the elements you want to capture
  //   const components = document.querySelectorAll(".component-to-capture");

  //   for (let index = 0; index < components.length; index++) {
  //     if (index > 0) {
  //       pdf.addPage();
  //     }

  //     const component = components[index];

  //     // Capture the component as an image using html2canvas
  //     const canvas = await html2canvas(component);

  //     const imgData = canvas.toDataURL("image/png");

  //     // Calculate the width and height based on landscape orientation
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();

  //     // Calculate the aspect ratio of the image
  //     const imgRatio = canvas.width / canvas.height;

  //     let imgWidth = pdfWidth;
  //     let imgHeight = pdfWidth / imgRatio;

  //     // Check if the image height exceeds the page height, if so, scale it down
  //     if (imgHeight > pdfHeight) {
  //       imgHeight = pdfHeight;
  //       imgWidth = pdfHeight * imgRatio;
  //     }

  //     // Calculate the center position for the image
  //     const x = (pdfWidth - imgWidth) / 2;
  //     const y = (pdfHeight - imgHeight) / 2;

  //     // Add the captured component image to the PDF
  //     pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
  //   }
  //   // Save or display the PDF
  //   pdf.save(`${companyName}_${sendTime}.pdf`);
  // }
  async LoadNewData(companyName, campaignId) {
    const [errorUpdatedCampaign, updatedCampaign] =
      await ReportAPIService.GetCampaignWithId(companyName, campaignId);
    if (errorUpdatedCampaign) {
      console.error("Error fetching campaigns:", errorUpdatedCampaign);
    }

    const [errorUpdatedReport, updatedReport] =
      await ReportAPIService.GetReportWithId(companyName, campaignId);
    if (errorUpdatedReport) {
      console.error("Error fetching report:", errorUpdatedReport);
    }
    const [errorUpdatedLinks, updatedLinks] =
      await ReportAPIService.GetLinksWithId(companyName, campaignId);
    if (errorUpdatedLinks) {
      console.error("Error fetching links:", errorUpdatedLinks);
    }
    const [errorUpdatedPreview, updatedPreview] =
      await ReportAPIService.GetPreviewWithId(companyName, campaignId);
    if (errorUpdatedPreview) {
      console.error("Error fetching preview:", errorUpdatedPreview);
    }

    return [updatedCampaign, updatedReport, updatedLinks, updatedPreview];
  }

  async LoadNewDataNoCampaign(companyName, campaignId) {
    const [errorReport, report] = await ReportAPIService.GetReportWithId(
      companyName,
      campaignId
    );
    if (errorReport) {
      console.error("Error fetching report:", errorReport);
    }
    const [errorLinks, links] = await ReportAPIService.GetLinksWithId(
      companyName,
      campaignId
    );
    if (errorLinks) {
      console.error("Error fetching links:", errorLinks);
    }
    const [errorPreview, preview] = await ReportAPIService.GetPreviewWithId(
      companyName,
      campaignId
    );
    if (errorPreview) {
      console.error("Error fetching preview:", errorPreview);
    }

    return [report, links, preview];
  }

  getBrokerNameForFileName(campaign) {
    let brokerName = campaign.settings.title.split(" - ");
    brokerName = brokerName[0].replace(/ /g, "-");
    return brokerName;
  }

  getBrokerNameWithSpace(campaign) {
    let brokerName = campaign.settings.title.split(" - ");
    brokerName = brokerName[0];
    return brokerName;
  }

  async fetchLiveData(companyName, campaigns) {
    let numberOfSavedCampaigns = 0;
    let finalIndex = -1;
    let [errorCompanies, fetchedCompanies] = await ReportAPIService.GetCompanies();
    if (errorCompanies) {
      console.error("Error fetching companies:", errorCompanies);
    }
    let fetchedCompany = null;
    if (fetchedCompanies) {
      fetchedCompany = fetchedCompanies.filter((company) => {
        if (company.code === companyName) {
          return company;
        }
        else {
          return null;
        }
      });
    }
    let [errorCampaignsList, campaignsList] = []
    if (fetchedCompany !== null) {
      let hasFolder = fetchedCompany[0].folderId !== "" ? true : false;
      [errorCampaignsList, campaignsList] =
      await ReportAPIService.GetCampaignsWithFolder(companyName, hasFolder);
      if (errorCampaignsList) {
        console.error("Error fetching campaigns:", errorCampaignsList);
      }
    }
    else {
      [errorCampaignsList, campaignsList] =
      await ReportAPIService.GetCampaigns(companyName);
      if (errorCampaignsList) {
        console.error("Error fetching campaigns:", errorCampaignsList);
      }
    }
    if (campaignsList) {
      for (let i = 0; i < campaignsList.length; i++) {
        let exist = campaigns.some((item) => item.id === campaignsList[i].id);
        if (!exist) {
          let [report, links, preview] = await this.LoadNewDataNoCampaign(
            companyName,
            campaignsList[i].id
          );
          let data = [campaignsList[i], report, links, preview];
          let url = "";
          if (companyName.includes("workshop-7") || companyName.includes("onpoint") || companyName.includes("david-kerr")) {
            url = `https://${startingUrl}/api/report/save/${companyName}/${FormatNumbersFunctions.formatDateWithTime(
              new Date(campaignsList[i].sendTime)
            )}`;
          } else if (companyName.includes("cloudbase")) {
            url = `https://${startingUrl}/api/report/save/${companyName}/${FormatNumbersFunctions.formatDateWithId(
              new Date(campaignsList[i].sendTime),
              campaignsList[i].id
            )}`;
          } else if (companyName.includes("crediflex") || companyName.includes("mtp")) {
            let brokerName = this.getBrokerNameForFileName(campaignsList[i]);
            url = `https://${startingUrl}/api/report/save/${companyName}/${brokerName}/${FormatNumbersFunctions.formatDateForFileName(
              new Date(campaignsList[i].sendTime)
            )}`;
          } else {
            url = `https://${startingUrl}/api/report/save/${companyName}/${FormatNumbersFunctions.formatDateForFileName(
              new Date(campaignsList[i].sendTime)
            )}`;
          }
          console.log(url)
          const [error, result] = await ReportAPIService.SaveData(url, data);
          if (error) {
            console.error("Error fetching preview:", error);
          }
          if (result) {
            numberOfSavedCampaigns++;
          }
        } else {
          finalIndex = i;
          break;
        }
      }
      if (finalIndex === numberOfSavedCampaigns || numberOfSavedCampaigns === campaignsList.length) {
        window.location.reload();
      }
    }
  }

  async getColour(companyName, pageNum, maxPageNumber) {
    let pageName = "";

    const [error, colours] = await ReportAPIService.GetCompanyColours(companyName);
    if (error) {
      console.error("Error fetching company image:", error);
    }

    //choose the colour based on the company name
    if (!companyName.includes("cloudbase")) {
      let gradientColour1 = colours[0];
      let gradientColour2 = colours[1];
      for (let i = 1; i <= maxPageNumber; i++) {
        if (companyName === "crediflex" || companyName === "mtp") {
          pageName = `.monthly-page-${i}-crediflex`;
        } else {
          pageName = `.monthly-page-${i}`;
        }
        const pageBackground = document.querySelector(pageName);
        if (pageBackground) {
          pageBackground.style.setProperty(
            "--gradient-color1",
            gradientColour1
          );
        }
      }
      const circleBackground = document.querySelectorAll(`.circle`);
      const secondaryNumberColour =
        document.querySelectorAll(`.secondary-number`);
      const previousMonthTextColour =
        document.querySelectorAll(`.legend-symbol-2`);
      const dotColour = document.querySelectorAll(`.chart-dot`);
      const lineColour = document.querySelectorAll(`.chart-line`);
      const labelColour = document.querySelectorAll(`.chart-label`);
      if (circleBackground)
        circleBackground.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      if (secondaryNumberColour)
        secondaryNumberColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      if (previousMonthTextColour)
        previousMonthTextColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      if (dotColour)
        dotColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      if (lineColour)
        lineColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      if (labelColour)
        labelColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
    } else {
      let gradientColour1 = colours[0];
      let gradientColour2 = colours[1];
      let gradientColour3 = colours[2];
      let gradientColour4 = colours[3];
      // Get the element with the gradient background
      const circleBackground = document.querySelectorAll(`.circle`);
      const secondaryNumberColour =
        document.querySelectorAll(`.secondary-number`);
      const previousMonthTextColour =
        document.querySelectorAll(`.legend-symbol-2`);
      const dotColour = document.querySelectorAll(`.chart-dot`);
      const lineColour = document.querySelectorAll(`.chart-line`);
      const labelColour = document.querySelectorAll(`.chart-label`);
      for (let i = 1; i <= 8; i++) {
        const pageBackground = document.querySelector(
          `.monthly-page-${i}-cloudbase`
        );
        if (i / 5 < 1) {
          if (pageBackground)
            pageBackground.style.setProperty("--gradient-color1", gradientColour1);
        } else {
          if (pageBackground)
            pageBackground.style.setProperty("--gradient-color1", gradientColour3);
        }
      }
      if (circleBackground)
        circleBackground.forEach((element, index) => {
          if (pageNum < 5) {
            if (index === 0)
              element.style.setProperty("--gradient-color2", gradientColour2);
            else if (index === 1)
              element.style.setProperty("--gradient-color2", gradientColour4);
          } else element.style.setProperty("--gradient-color2", gradientColour4);
        });
      if (secondaryNumberColour)
        secondaryNumberColour.forEach((element) => {
          if (pageNum < 5)
            element.style.setProperty("--gradient-color2", gradientColour2);
          else element.style.setProperty("--gradient-color2", gradientColour4);
        });
      if (previousMonthTextColour)
        previousMonthTextColour.forEach((element) => {
          if (pageNum < 5)
            element.style.setProperty("--gradient-color2", gradientColour2);
          else element.style.setProperty("--gradient-color2", gradientColour4);
        });

      if (dotColour)
        dotColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      if (lineColour)
        lineColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      if (labelColour)
        labelColour.forEach((element) => {
          element.style.setProperty("--gradient-color2", gradientColour2);
        });
      const page6CloudbaseBackground = document.querySelector(
        `.monthly-page-6-cloudbase`
      );
      if (page6CloudbaseBackground) {
        const secondaryNumberColourCloudbase =
          page6CloudbaseBackground.querySelectorAll(`.secondary-number`);
        const previousMonthTextColourCloudbase =
          page6CloudbaseBackground.querySelectorAll(`.legend-symbol-2`);
        if (secondaryNumberColourCloudbase)
          secondaryNumberColourCloudbase.forEach((element) => {
            element.style.setProperty("--gradient-color2", gradientColour4);
          });
        if (previousMonthTextColourCloudbase)
          previousMonthTextColourCloudbase.forEach((element) => {
            element.style.setProperty("--gradient-color2", gradientColour4);
          });
      }
      const page7CloudbaseBackground = document.querySelector(
        `.monthly-page-7-cloudbase`
      );
      if (page7CloudbaseBackground) {
        const dotColour =
          page7CloudbaseBackground.querySelectorAll(`.chart-dot`);
        const lineColour =
          page7CloudbaseBackground.querySelectorAll(`.chart-line`);
        const labelColour =
          page7CloudbaseBackground.querySelectorAll(`.chart-label`);
        const previousMonthTextColourCloudbase =
          page7CloudbaseBackground.querySelectorAll(`.legend-symbol-2`);
        if (previousMonthTextColourCloudbase)
          previousMonthTextColourCloudbase.forEach((element) => {
            element.style.setProperty("--gradient-color2", gradientColour4);
          });
        if (dotColour)
          dotColour.forEach((element) => {
            element.style.setProperty("--gradient-color2", gradientColour4);
          });
        if (lineColour)
          lineColour.forEach((element) => {
            element.style.setProperty("--gradient-color2", gradientColour4);
          });
        if (labelColour)
          labelColour.forEach((element) => {
            element.style.setProperty("--gradient-color2", gradientColour4);
          });
      }
      const page8CloudbaseBackground = document.querySelector(
        `.monthly-page-8-cloudbase`
      );
      if (page8CloudbaseBackground) {
        const dotColour =
          page8CloudbaseBackground.querySelectorAll(`.chart-dot`);
        const lineColour =
          page8CloudbaseBackground.querySelectorAll(`.chart-line`);
        const labelColour =
          page8CloudbaseBackground.querySelectorAll(`.chart-label`);
        const previousMonthTextColourCloudbase =
          page8CloudbaseBackground.querySelectorAll(`.legend-symbol-2`);
        if (previousMonthTextColourCloudbase)
          previousMonthTextColourCloudbase.forEach((element) => {
            element.style.setProperty("--gradient-color2", gradientColour4);
          });
        if (dotColour)
          dotColour.forEach((element) => {
            element.style.setProperty("--gradient-color2", gradientColour4);
          });
        if (lineColour)
          lineColour.forEach((element) => {
            element.style.setProperty("--gradient-color2", gradientColour4);
          });
        if (labelColour)
          labelColour.forEach((element) => {
            element.style.setProperty("--gradient-color2", gradientColour4);
          });
      }
    }
  }
}
const helperFunctions = new HelperFunctions();
export default helperFunctions;
