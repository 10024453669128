import React, { useState, useEffect } from "react";
import YearlyPage1 from "./YearlyPage1";
import YearlyPage2 from "./YearlyPage2";
import YearlyPage3 from "./YearlyPage3";
import YearlyPage4 from "./YearlyPage4";
// import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReportAPIService from "../api/reports.api.js";
import HelperFunctions from "../HelperFunctions/HelperFunctions.js";

export default function YearlyReport() {
  const urlList = window.location.href.split("//").pop().split("/");
  const companyName = urlList[1];
  const [pageNum, setPageNum] = useState(1);
  const [savedCampaigns, setSavedCampaigns] = useState([]);
  const [savedReports, setSavedReports] = useState([]);

  useEffect(() => {
    async function getContentFromSavedFile(returnedData) {
      var tempCampaigns = [];
      var tempReports = [];

      for (const element of returnedData) {
        const url = `https://${HelperFunctions.getStartingUrl()}/api/report/saved/${companyName}/${element}`;
        const returnedResponse = await fetch(url);
        const returnedData = await returnedResponse.json();
        if (returnedData[0] && returnedData[1]) {
          tempCampaigns = tempCampaigns.concat(returnedData[0]);
          tempReports = tempReports.concat(returnedData[1]);
        }
      }
      tempCampaigns.sort((a, b) => new Date(b.sendTime) - new Date(a.sendTime));
      tempReports.sort((a, b) => new Date(b.sendTime) - new Date(a.sendTime));
      setSavedCampaigns(tempCampaigns);
      setSavedReports(tempReports);
    }

    async function getSavedFiles() {
      const [errorReturnedData, returnedData] =
        await ReportAPIService.GetSavedFileNames(companyName);
      if (errorReturnedData) {
        console.error("Error fetching campaigns:", errorReturnedData);
      }
      if (returnedData) {
        returnedData.forEach((element, index, array) => {
          array[index] = element.split("\\").pop();
        });
      }
      return returnedData;
    }

    const fetchData = async () => {
      const returnedData = await getSavedFiles();
      await getContentFromSavedFile(returnedData);
    };

    fetchData();
  }, [companyName, pageNum]);

  useEffect(() => {
    HelperFunctions.getColour(companyName, pageNum, 4);
  });

  function nextClicked() {
    setPageNum(pageNum + 1);
  }

  function prevClicked() {
    setPageNum(pageNum - 1);
  }

  function backClicked() {
    window.location.href = `/`;
  }

  async function generateImages() {
    // Select the elements you want to capture
    const components = document.querySelectorAll(".component-to-capture");

    // let iframeCaptured = false; // Flag to ensure iframe is captured only once

    for (let index = 0; index < components.length; index++) {
      const component = components[index];

      // Capture the component as an image using html2canvas
      const canvas = await html2canvas(component);

      // Convert the canvas to a data URL (image)
      const imgData = canvas.toDataURL("image/png");

      // Create a link element to save the component image as a file
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${companyName}_${index + 1}.png`; // You can customize the filename here
      link.style.display = "none"; // Hide the link

      // Trigger a click event on the link to save the component image
      link.click();
    }
  }

  // async function generatePDF() {
  //   const pdf = new jsPDF("l", "mm", "a4");

  //   // Select the elements you want to capture
  //   const components = document.querySelectorAll(".component-to-capture");

  //   for (let index = 0; index < components.length; index++) {
  //     if (index > 0) {
  //       pdf.addPage();
  //     }

  //     const component = components[index];

  //     // Capture the component as an image using html2canvas
  //     const canvas = await html2canvas(component);

  //     const imgData = canvas.toDataURL("image/png");

  //     // Calculate the width and height based on landscape orientation
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();

  //     // Calculate the aspect ratio of the image
  //     const imgRatio = canvas.width / canvas.height;

  //     let imgWidth = pdfWidth;
  //     let imgHeight = pdfWidth / imgRatio;

  //     // Check if the image height exceeds the page height, if so, scale it down
  //     if (imgHeight > pdfHeight) {
  //       imgHeight = pdfHeight;
  //       imgWidth = pdfHeight * imgRatio;
  //     }

  //     // Calculate the center position for the image
  //     const x = (pdfWidth - imgWidth) / 2;
  //     const y = (pdfHeight - imgHeight) / 2;

  //     // Add the captured component image to the PDF
  //     pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
  //   }
  //   // Save or display the PDF
  //   pdf.save(`${companyName}_${new Date().getFullYear()}.pdf`);
  // }

  function getLast12Campaigns() {
    var campaignsList = savedCampaigns.slice(0, 12);
    return campaignsList;
  }

  function getLast12Reports() {
    var reportsList = savedReports.slice(0, 12);
    return reportsList;
  }

  function choosePage() {
    if (pageNum === 1) {
      return <YearlyPage1 campaigns={getLast12Campaigns()} />;
    } else if (pageNum === 2) {
      return <YearlyPage2 campaigns={getLast12Campaigns()} />;
    } else if (pageNum === 3) {
      return (
        <YearlyPage3
          campaigns={getLast12Campaigns()}
          reports={getLast12Reports()}
        />
      );
    } else if (pageNum === 4) {
      return (
        <YearlyPage4
          campaigns={getLast12Campaigns()}
          reports={getLast12Reports()}
        />
      );
    }
    return null;
  }

  function showPages() {
    return (
      <>
        <div className="monthly-report" id="monthly-report">
          <div className="yearly-page" id={`yearly-page-${pageNum}`}>
            {choosePage()}
            {pageNum !== 0 && pageNum !== 1 ? (
              <button
                className="btn-general btn-prev"
                onClick={() => prevClicked()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  viewBox="0 0 320 512"
                >
                  <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                </svg>
              </button>
            ) : null}
            {pageNum !== 4 ? (
              <button
                className="btn-general btn-next"
                onClick={() => nextClicked()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  viewBox="0 0 320 512"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </button>
            ) : null}
            <button
              className="btn-general btn-export"
              onClick={() => {
                setPageNum(0);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2rem"
                viewBox="0 0 512 512"
              >
                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
              </svg>
            </button>
          </div>
        </div>
      </>
    );
  }

  function main() {
    if (savedCampaigns.length > 0 && savedReports.length > 0) {
      if (pageNum === 0) {
        return (
          <>
            <div className="yearly-page-0" id="yearly-page-0">
              <button
                className="btn-general btn-next"
                onClick={() => nextClicked()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="3em"
                  viewBox="0 0 320 512"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </button>
              <button
                className="btn-general btn-export"
                onClick={() => {
                  setPageNum(0);
                  // generatePDF();
                  generateImages();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="2rem"
                  viewBox="0 0 512 512"
                >
                  <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                </svg>
              </button>
              <div className="monthly-pages" id="monthly-pages">
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="yearly-page component-to-capture"
                    id="monthly-page-1"
                  >
                    <YearlyPage1 campaigns={getLast12Campaigns()} />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="yearly-page component-to-capture"
                    id="monthly-page-2"
                  >
                    <YearlyPage2 campaigns={getLast12Campaigns()} />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="yearly-page component-to-capture"
                    id="monthly-page-3"
                  >
                    <YearlyPage3
                      campaigns={getLast12Campaigns()}
                      reports={getLast12Reports()}
                    />
                  </div>
                </div>
                <div className="monthly-report" id="monthly-report">
                  <div
                    className="yearly-page component-to-capture"
                    id="monthly-page-4"
                  >
                    <YearlyPage4
                      campaigns={getLast12Campaigns()}
                      reports={getLast12Reports()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      } else if (pageNum > 0) {
        return showPages();
      } else {
        return (
          <>
            <div className="monthly-report" id="monthly-report">
              Loading...
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <div className="monthly-report" id="monthly-report">
            Loading...
          </div>
        </>
      );
    }
  }

  return (
    <>
      <button
        className="btn-general btn-back"
        onClick={() => {
          backClicked();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2rem"
          viewBox="0 0 448 512"
        >
          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
        </svg>
      </button>
      {main()}
    </>
  );
}
