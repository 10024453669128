import React, { useState, useEffect } from "react";
import {
  LabelList,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

export default function MonthlyPage5({ campaigns, reports }) {
  const [firstChartData, setFirstChartData] = useState([]);
  const [secondChartData, setSecondChartData] = useState([]);
  const [dataSet, setDataSet] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: window.innerHeight / 2 + 200,
    height: window.innerHeight / 2,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerHeight / 2 + 200,
        height: window.innerHeight / 2,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function updateChartData() {
    setFirstChartData([
      {
        name: campaigns[5] ? changeDate(new Date(campaigns[5].sendTime)) : "",
        clicked: campaigns[5]
          ? campaigns[5].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[5] ? campaigns[5].reportSummary.clicks : null,
      },
      {
        name: campaigns[4] ? changeDate(new Date(campaigns[4].sendTime)) : "",
        clicked: campaigns[4]
          ? campaigns[4].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[4] ? campaigns[4].reportSummary.clicks : null,
      },
      {
        name: campaigns[3] ? changeDate(new Date(campaigns[3].sendTime)) : "",
        clicked: campaigns[3]
          ? campaigns[3].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[3] ? campaigns[3].reportSummary.clicks : null,
      },
      {
        name: campaigns[2] ? changeDate(new Date(campaigns[2].sendTime)) : "",
        clicked: campaigns[2]
          ? campaigns[2].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[2] ? campaigns[2].reportSummary.clicks : null,
      },
      {
        name: campaigns[1] ? changeDate(new Date(campaigns[1].sendTime)) : "",
        clicked: campaigns[1]
          ? campaigns[1].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[1] ? campaigns[1].reportSummary.clicks : null,
      },
      {
        name: campaigns[0] ? changeDate(new Date(campaigns[0].sendTime)) : "",
        clicked: campaigns[0]
          ? campaigns[0].reportSummary.subscriberClicks
          : null,
        clicks: campaigns[0] ? campaigns[0].reportSummary.clicks : null,
      },
    ]);
    setSecondChartData([
      {
        name: campaigns[5] ? changeDate(new Date(campaigns[5].sendTime)) : "",
        openRate: reports[5]
          ? (
              Math.round(
                (reports[5].clicks.uniqueSubscriberClicks /
                  reports[5].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: campaigns[4] ? changeDate(new Date(campaigns[4].sendTime)) : "",
        openRate: reports[4]
          ? (
              Math.round(
                (reports[4].clicks.uniqueSubscriberClicks /
                  reports[4].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: campaigns[3] ? changeDate(new Date(campaigns[3].sendTime)) : "",
        openRate: reports[3]
          ? (
              Math.round(
                (reports[3].clicks.uniqueSubscriberClicks /
                  reports[3].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: campaigns[2] ? changeDate(new Date(campaigns[2].sendTime)) : "",
        openRate: reports[2]
          ? (
              Math.round(
                (reports[2].clicks.uniqueSubscriberClicks /
                  reports[2].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: campaigns[1] ? changeDate(new Date(campaigns[1].sendTime)) : "",
        openRate: reports[1]
          ? (
              Math.round(
                (reports[1].clicks.uniqueSubscriberClicks /
                  reports[1].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
      {
        name: campaigns[0] ? changeDate(new Date(campaigns[0].sendTime)) : "",
        openRate: reports[0]
          ? (
              Math.round(
                (reports[0].clicks.uniqueSubscriberClicks /
                  reports[0].opens.uniqueOpens) *
                  10000
              ).toFixed(2) / 100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : null,
      },
    ]);
  }

  function changeDate(date) {
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    if (month === "01") month = "Jan";
    else if (month === "02") month = "Feb";
    else if (month === "03") month = "Mar";
    else if (month === "04") month = "Apr";
    else if (month === "05") month = "May";
    else if (month === "06") month = "Jun";
    else if (month === "07") month = "Jul";
    else if (month === "08") month = "Aug";
    else if (month === "09") month = "Sep";
    else if (month === "10") month = "Oct";
    else if (month === "11") month = "Nov";
    else if (month === "12") month = "Dec";
    return `${month}`;
  }

  function getMinMax() {
    const minValue = Math.min(
      ...firstChartData
        .slice(1, -1)
        .map((item) => Math.min(item.clicked, item.clicks))
    );
    const maxValue = Math.max(
      ...firstChartData
        .slice(1, -1)
        .map((item) => Math.max(item.clicked, item.clicks))
    );

    // Round the minimum value down to the nearest multiple of 100
    const yMin = Math.floor(minValue / 10) * 10;

    // Round the maximum value up to the nearest multiple of 100
    const yMax = Math.ceil(maxValue / 10) * 10;

    // Set the Y-axis domain
    const yDomain = [yMin - 20, yMax + 50];
    return yDomain;
  }

  function getMinMax2() {
    const maxValue = Math.max(
      ...secondChartData.slice(1, -1).map((item) => Math.max(item.openRate))
    );

    const yMax = Math.ceil(maxValue);

    const yDomain = [0, yMax + 10];
    return yDomain;
  }

  function main() {
    if (!dataSet) {
      updateChartData();
      setDataSet(true);
    } else {
      return (
        <>
          <div className="row content">
            <div className="col-12 column">
              <h1
                className="heading"
                style={{
                  fontSize: "5.4vh",
                  textAlign: "left",
                  marginTop: "40px",
                  marginLeft: "40px",
                  marginBottom: "4vh",
                  padding: 0,
                  width: "90%",
                  height: "fit-content",
                }}
              >
                MONTHLY COMPARISON - CLICKS
              </h1>
              <div className="row" style={{ height: "fit-content" }}>
                <div className="col-md-6" style={{ height: "fit-content" }}>
                  <div className="legend">
                    <div className="legend-item">
                      <span className="legend-symbol legend-symbol-2">●</span>
                      Clicked Contacts
                    </div>
                    <div className="legend-item">
                      <span className="legend-symbol legend-symbol-3">●</span>
                      Newsletter Clicks
                    </div>
                  </div>
                </div>
                <div className="col-md-6" style={{ height: "fit-content" }}>
                  <div className="legend">
                    <div className="legend-item">
                      <span className="legend-symbol legend-symbol-2">●</span>
                      Clicks per Unique Open
                    </div>
                  </div>
                </div>
              </div>
              <div className="row chart-container">
                <div className="col-md-6 first-chart">
                  <LineChart
                    width={dimensions.width}
                    height={dimensions.height}
                    data={firstChartData}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                    <YAxis domain={getMinMax()} />
                    <Line
                      className="chart-line"
                      name="Clicked Contacts"
                      type="linear"
                      dataKey="clicked"
                      strokeWidth={3}
                      dot={{ r: 5, className: "chart-dot" }}
                    >
                      <LabelList
                        className="chart-label"
                        dataKey="clicked"
                        position="bottom"
                        dy={10}
                        formatter={(value) =>
                          Math.floor(value / 1000) > 0
                            ? Math.floor(value / 1000) +
                              "," +
                              (value % 1000 < 100
                                ? (value % 1000) % 100 < 10
                                  ? `00${value % 1000}`
                                  : `0${value % 1000}`
                                : value % 1000)
                            : value
                        }
                      />
                    </Line>
                    <Line
                      name="Newsletter Clicks"
                      type="linear"
                      dataKey="clicks"
                      stroke="#ea4335"
                      strokeWidth={3}
                      dot={{ r: 5, fill: "#ea4335" }}
                    >
                      <LabelList
                        dataKey="clicks"
                        position="top"
                        stroke="#ea4335"
                        dy={-10}
                        formatter={(value) =>
                          Math.floor(value / 1000) > 0
                            ? Math.floor(value / 1000) +
                              "," +
                              (value % 1000 < 100
                                ? (value % 1000) % 100 < 10
                                  ? `00${value % 1000}`
                                  : `0${value % 1000}`
                                : value % 1000)
                            : value
                        }
                      />
                    </Line>
                  </LineChart>
                </div>
                <div className="col-md-6 second-chart">
                  <LineChart
                    width={dimensions.width}
                    height={dimensions.height}
                    data={secondChartData}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                    <YAxis
                      domain={getMinMax2()}
                      tickFormatter={(value) => `${value}%`}
                    />
                    <Line
                      className="chart-line"
                      name="Clicks per Unique Open"
                      type="linear"
                      dataKey="openRate"
                      strokeWidth={3}
                      dot={{ r: 5, className: "chart-dot" }}
                    >
                      <LabelList
                        className="chart-label"
                        dataKey="openRate"
                        position="top"
                        dy={-10}
                        formatter={(value) => `${value}%`}
                      />
                    </Line>
                  </LineChart>
                </div>
              </div>
            </div>
          </div>
          <img src={"/images/HitSend-logo.png"} alt="Hit Send Logo" className="hitsend-logo" />
        </>
      );
    }
  }

  return <>{main()}</>;
}
