import React, { useState, useEffect } from "react";
import ReportAPIService from "./api/reports.api";

export default function Home() {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    async function fetchCompanies() {
      try {
        const [errorCompanies, fetchedCompanies] = await ReportAPIService.GetCompanies();
        if (errorCompanies) {
          console.error("Error fetching companies:", errorCompanies);
        }
        setCompanies(fetchedCompanies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    }

    fetchCompanies();
  }, []);

  // const companies = [
  //   { Name: "System 7", Code: "system7" },
  //   { Name: "Bug King", Code: "bug-king" },

  //   { Name: "Angel Property Managers", Code: "angel-property-managers" },
  //   {
  //     Name: "Cloudbase Print And Promotion",
  //     Code: "cloudbase-print-and-promotion",
  //   },
  //   { Name: "GreenSide Energy Solutions", Code: "greenside-energy-solutions" },
  //   {
  //     Name: "Metalcraft Insulated Panels",
  //     Code: "metalcraft-insulated-panels",
  //   },
  //   { Name: "Workshop 7", Code: "workshop-7" },
  //   { Name: "CrediFlex", Code: "crediflex" },
  //   { Name: "Mat Wood", Code: "mat-wood" },
  //   { Name: "MTP", Code: "mtp" },
  //   { Name: "Vita Pacific", Code: "vita-pacific" },
  //   { Name: "Contact Lens Express", Code: "contact-lens-express" },
  //   { Name: "Scafman", Code: "scafman" },
  //   { Name: "Leck Electrical", Code: "leck-electrical" },
  //   { Name: "David Kerr", Code: "david-kerr" },
  //   { Name: "OnPoint Financial Advice", Code: "onpoint-financial-advice" },
  //   { Name: "Koru Staging", Code: "koru-staging" },
  // ];

  function main() {
    if (companies.length > 0) {
      return (
        <>
          <div className="reports">
            <div className="row" style={{ marginTop: "150px" }}>
              <h1>Company Reports</h1>
            </div>
            <div className="row" style={{ marginTop: "50px" }}>
              <table className="dataTable">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Reports List</th>
                    <th>Yearly Report</th>
                  </tr>
                </thead>
                <tbody>
                  {companies.map((company) => (
                    <tr key={company.code}>
                      <td>{company.name}</td>
                      <td>
                        <a href={`/${company.code}/reports-list`}>
                          Monthly Reports
                        </a>
                      </td>
                      <td>
                        <a href={`/${company.code}/yearly-report`}>
                          Yearly Reports
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <img
              className="hitsend-logo-home"
              src={"/images/HitSend-logo.png"}
              alt="HitSend Logo"
            />
          </div>
        </>
      );
    } else {
      return (
        <div className="reports">
          <div className="row" style={{ marginTop: "150px" }}>
            <h1>Company Reports</h1>
          </div>
          <div className="row" style={{ marginTop: "50px" }}>
            <h2>Loading...</h2>
          </div>
        </div>
      )
    }
  }

  return <>{main()}</>;
}
